import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';

const routes: Routes = [
  {
    path: '',
    // first page when app load
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },

  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'dieticians',
    loadChildren: () => import('./dieticians/dieticians.module').then(m => m.DieticiansPageModule)
  },
  {
    path: 'dieticians-details',
    loadChildren: () => import('./dieticians-details/dieticians-details.module').then(m => m.DieticiansDetailsPageModule)
  },
  {
    path: 'dietplan-details',
    loadChildren: () => import('./dietplan-details/dietplan-details.module').then(m => m.DietplanDetailsPageModule)
  },
  {
    path: 'explore',
    loadChildren: () => import('./explore/explore.module').then(m => m.ExplorePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./editprofile/editprofile.module').then(m => m.EditprofilePageModule)
  },
  {
    path: 'bmi',
    loadChildren: () => import('./bmi/bmi.module').then(m => m.BmiPageModule)
  },
  {
    path: 'medical-conditions',
    loadChildren: () => import('./modals/medical-conditions/medical-conditions.module').then(m => m.MedicalConditionsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modals/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./modals/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'login2',
    loadChildren: () => import('./modals/login2/login2.module').then(m => m.Login2PageModule)
  },
  {
    path: 'consultation-details',
    loadChildren: () => import('./consultation-details/consultation-details.module').then(m => m.ConsultationDetailsPageModule)
  },
  {
    path: 'rda',
    loadChildren: () => import('./rda/rda.module').then(m => m.RdaPageModule)
  },
  {
    path: 'kids-growth-chart',
    loadChildren: () => import('./kids-growth-chart/kids-growth-chart.module').then(m => m.KidsGrowthChartPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./modals/filter/filter.module').then(m => m.FilterPageModule)
  },
  {
    path: 'sortby',
    loadChildren: () => import('./modals/sortby/sortby.module').then(m => m.SortbyPageModule)
  },
  {
    path: 'consulation-details-paid',
    loadChildren: () => import('./consulation-details-paid/consulation-details-paid.module').then(m => m.ConsulationDetailsPaidPageModule)
  },
  {
    path: 'mydietchart',
    loadChildren: () => import('./mydietchart/mydietchart.module').then(m => m.MydietchartPageModule)
  },
  {
    path: 'viewattachedfile',
    loadChildren: () => import('./viewattachedfile/viewattachedfile.module').then(m => m.ViewattachedfilePageModule)
  },
  {
    path: 'instructions',
    loadChildren: () => import('./instructions/instructions.module').then(m => m.InstructionsPageModule)
  },
  {
    path: 'rating',
    loadChildren: () => import('./rating/rating.module').then(m => m.RatingPageModule)
  },
  {
    path: 'myconsulations',
    loadChildren: () => import('./myconsulations/myconsulations.module').then(m => m.MyconsulationsPageModule)
  },
  {
    path: 'moremenu',
    loadChildren: () => import('./modals/moremenu/moremenu.module').then(m => m.MoremenuPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsPageModule)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./terms-of-use/terms-of-use.module').then(m => m.TermsOfUsePageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackPageModule)
  },

  {
    path: 'signupack',
    loadChildren: () => import('./modals/signupack/signupack.module').then(m => m.SignupackPageModule)
  },

  {
    path: 'consultation-completed',
    loadChildren: () => import('./dietician-pages/consultation-completed/consultation-completed.module').then(m => m.ConsultationCompletedPageModule)
  },
  {
    path: 'view-diet-chart',
    loadChildren: () => import('./dietician-pages/view-diet-chart/view-diet-chart.module').then(m => m.ViewDietChartPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./dietician-pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'add-package',
    loadChildren: () => import('./dietician-pages/add-package/add-package.module').then(m => m.AddPackagePageModule)
  },
  {
    path: 'edit-package',
    loadChildren: () => import('./dietician-pages/edit-package/edit-package.module').then(m => m.EditPackagePageModule)
  },
  {
    path: 'packages',
    loadChildren: () => import('./dietician-pages/packages/packages.module').then(m => m.PackagesPageModule)
  },
  {
    path: 'template',
    loadChildren: () => import('./dietician-pages/template/template.module').then(m => m.TemplatePageModule)
  },
  {
    path: 'add-template',
    loadChildren: () => import('./dietician-pages/add-template/add-template.module').then(m => m.AddTemplatePageModule)
  },
  {
    path: 'consultation-details',
    loadChildren: () => import('./dietician-pages/consultation-details/consultation-details.module').then(m => m.ConsultationDetailsPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./dietician-pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule)
  },
  {
    path: 'dietician-profile',
    loadChildren: () => import('./dietician-pages/dietician-profile/dietician-profile.module').then(m => m.DieticianProfilePageModule)
  },
  {
    path: 'edit-dietician-profile',
    loadChildren: () => import('./dietician-pages/edit-dietician-profile/edit-dietician-profile.module').then(m => m.EditDieticianProfilePageModule)
  },
  {
    path: 'recipient-details',
    loadChildren: () => import('./recipient-details/recipient-details.module').then(m => m.RecipientDetailsPageModule)
  },
  {
    path: 'add-template-schedule',
    loadChildren: () => import('./dietician-pages/add-template-schedule/add-template-schedule.module').then(m => m.AddTemplateSchedulePageModule)
  },
  // {
  //   path: 'add-template-schedule',
  //   loadChildren: () => import('./dietician-pages/add-template-schedule/add-template-schedule.module').then(m => m.AddTemplateSchedulePageModule)
  // },
  {
    path: 'assign-dietchart',
    loadChildren: () => import('./dietician-pages/assign-dietchart/assign-dietchart.module').then(m => m.AssignDietchartPageModule)
  },
  {
    path: 'view-template',
    loadChildren: () => import('./dietician-pages/view-template/view-template.module').then(m => m.ViewTemplatePageModule)
  },
  {
    path: 'sociallogin',
    loadChildren: () => import('./sociallogin/sociallogin.module').then(m => m.SocialloginPageModule)
  },
  {
    path: 'offered-package-details',
    loadChildren: () => import('./modals/offered-package-details/offered-package-details.module').then(m => m.OfferedPackageDetailsPageModule)
  },
  {
    path: 'about-me',
    loadChildren: () => import('./modals/about-me/about-me.module').then(m => m.AboutMePageModule)
  },
  {
    path: 'activediet-chart',
    loadChildren: () => import('./activediet-chart/activediet-chart.module').then(m => m.ActivedietChartPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'socialloginuser',
    loadChildren: () => import('./socialloginuser/socialloginuser.module').then(m => m.SocialloginuserPageModule)
  },
  {
    path: 'bmiinfo',
    loadChildren: () => import('./modals/bmiinfo/bmiinfo.module').then(m => m.BmiinfoPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
