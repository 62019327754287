import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterOutlet } from '@angular/router';
import { IonicModule, IonicRouteStrategy, } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MoremenuPageModule } from '../app/modals/moremenu/moremenu.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonNav } from "@ionic/angular";
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from "../environments/environment";
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { DatetransformPipe } from './pipes/date/datetransform.pipe';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File, FileEntry } from "@ionic-native/file/ngx";
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';

import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { Facebook } from "@ionic-native/facebook/ngx";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { RatingModule } from 'ng-starrating';
import { StarRatingComponent } from 'ng-starrating';

@NgModule({
  declarations: [AppComponent, DatetransformPipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(
      environment[environment.currentEnvironment].firebaseConfig,
    ),
    AppRoutingModule,
    HttpClientModule,
    MoremenuPageModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    RatingModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatetransformPipe,
    Push,
    IonNav,
    Camera, File,
    InAppBrowser,
    FileTransfer, DocumentViewer,
    Network,
    Facebook,
    GooglePlus,
    SocialSharing,
    StarRatingComponent,
    RouterOutlet,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
