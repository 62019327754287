import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit {
  shownGroup = null;
  information: any[];
  constructor(private navCtrl: NavController, private modalCtrl: ModalController) {
    let faq = {
      "items": [
        {
          "categoryName": "General",
          "questions": [{
            "question": "What is the App about?",
            "answer": "Oolsum is an online diet consultation platform that allows people looking for diet consultation services to connect with dieticians/nutritionists across India. They can consult any dietician who is available online at the given time for a fee."
          },
          {
            "question": "Is there any subscription fee for using the App?",
            "answer": "The app is completely free. People who want to consult dietician via this app has to pay consultation fee as published in this app."
          },
          {
            "question": "What will I get from the app?",
            "answer": "<p>If you consult any dietician via this app, then you will be assigned a diet chart by the dietician. You can view & follow the diet chart. In future we'll provide you complete tracking feature and many more other features that you can't resist.</p> <p>You can also view the recommended dietary allowance (how much nutrients body requires based on age, gender & nature of work).</p> <p>You can see the kids growth chart as published by Indian Academy of Pediatrics & World Health Organization.</p>"
          },
          {
            "question": "How secure is this App?",
            "answer": "The app is fully secure with user credentials stored in Industry's leading authentication system. The user data is secured with multi layered security rules applied and stored on the Industry's leading cloud provider."
          },
          {
            "question": "What kind of users can benefit from using this App?",
            "answer": "The app is very useful for people who want to go for a diet consultation but don't have time to go for a physical visit. On the other end for dieticians/nutritionists, hospitals & other health institutions offering diet consultations to scale up their services across India."
          },
          {
            "question": "How does the App work?",
            "title": "For general user :",
            "answer": "Register using facebook, google or email with user type as 'User'. (Activate account, if registered via email)</p> <p>After logging in, view dieticians from Hospitals, Other Institutions or independent dieticians.</p> <p>Click on 'Consult' against dietician whom you want to consult.</p> <p>A request will go the dietician. Dietician accepts or rejects your request. If dietician rejects your request, you can select other dietician.</p> <p>If dietician accepts your request, then you will be directed to payment page.</p> <p>After making payment, you will be added to the pending consultation Q of the dietician.</p> <p>Dietician will initiate discussion via text chat.</p> <p>After understanding your requirements, dietician will assign a diet chart for you.",
            "subTitle": "For dietician / nutritionist :",
            "subAnswer": "<p>Register using facebook, google or email with user type as 'Dietician'. (Activate account, if registered via email). Mail your qualification, experience & other proofs as requested in the app to <a href=\"mailto:intouch@oolsum.com?subject=Enquiry\">intouch@oolsum.com</a>.</p> <p>Once we receive the documents, we'll verify the documents and mark the documents as verified. You can do consultation only after documents have been verified.</p> <p>After logging in (once documents are verified), view dashboard where you can go to consultation mode (ONLINE), manage templates, view pending diet chart assignments etc.</p> <p>When ONLINE, you will received requests from clients for consultation services. Based on your availability, you can either accept or reject requests.</p> <p>If you accept the request, client will be directed for payment. After payment, client will be added to your pending consultation Q.</p> <p>You can pick the clients in your consultation Q sequentially only.</p> <p>For any technical reasons, if you are not able to continue consultation with the client, you can skip the consultation by pressing SKIP.</p> <p>After success completion of consultation via text chat, you can work and assign diet chart to the client. You can do the same during chat session also.</p>"
          },
          ]
        },
        {
          "categoryName": "Login & Registration",
          "questions": [
            {
              "question": "How does facebook & google login work?",
              "answer": "<p>From our app, we have integrated with facebook and google authentication. When you login using facebook or google, you will be directed to their respective login pages. Once these website authenticate you, then we will receive your name & email from their sites which will be used for creating your profile.</p> <p>Subsequent logins will be authenticated via facebook & google login pages and after authentication will directly take you to the home page of oolsum.</p>"
            },
            {
              "question": "How does email registration work?",
              "answer": "When you register using email registration, then an activation link is sent to provided email address. You need to activate your account by clicking on the link. Until such time you will be navigated to acknowledgement screen when you login with the given credentials."
            },
            {
              "question": "I haven't received email for activation.",
              "answer": "If you have not received email for activation, then please click on the resend verification link in the acknowledgement screen. If you have already come out of the app/ acknowledgement screen, then login with your given credentials. It will take you to the acknowledgement screen."
            },
            {
              "question": "I have registered using email method. Can I register again using facebook or google ?",
              "answer": "You can login using facebook or google if the same email is used in these accounts. If your facebook or gmail account is different from what you have already registered then new account is created with that email-id."
            },
            {
              "question": "I'm a dietician. I have registered and my account is active but not able to see any option",
              "answer": "For all dieticians & nutritionists there is a document verification process. Kindly send your experience letter, professional certificates, ID & Address proofs to <a href=\"mailto:intouch@oolsum.com?subject=Enquiry\">intouch@oolsum.com</a>"
            }]
        },
        {
          "categoryName": "Payment",
          "questions": [
            {
              "question": "I have made the payment , but unable to consult with a dietitian",
              "answer": "Once you made the payment, you are eligible to consult the dietcian. For any reasons, if consultation didn't happened then your consultation is shown as 'Pending Consultation' in the Hospitals/dieticians listing page. If dietician is online, you can continue your consultation."
            }]
        }
      ]
    };
    this.information = faq.items;
  }
  toggleGroup(group) {
    if (this.isGroupShown(group)) {
      this.shownGroup = null;
    } else {
      this.shownGroup = group;
    }
  };

  isGroupShown(group) {
    return this.shownGroup === group;
  };
  ngOnInit() {
  }
  dismissView() {
    this.navCtrl.pop();
    this.modalCtrl.dismiss();
  }

}
