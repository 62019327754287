import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController, ModalController } from '@ionic/angular';
import { AboutUsPage } from 'src/app/about-us/about-us.page';
import { ContactUsPage } from 'src/app/contact-us/contact-us.page';
import { FaqPage } from 'src/app/faq/faq.page';
import { PrivacyPolicyPage } from 'src/app/privacy-policy/privacy-policy.page';
import { TermsOfUsePage } from 'src/app/terms-of-use/terms-of-use.page';
@Component({
  selector: 'app-moremenu',
  templateUrl: './moremenu.page.html',
  styleUrls: ['./moremenu.page.scss'],
})
export class MoremenuPage implements OnInit {

  constructor(private popoverCtrl: PopoverController,
    private modalController: ModalController) { }

  ngOnInit() {
  }

  openPage(title) {
    this.popoverCtrl.dismiss({
      pageTitle: title
    })
  }

  async openAsModal(term) {
    let pageName;
    if (term == 'TU') {
      pageName = TermsOfUsePage;
    }
    if (term == 'PP') {
      pageName = PrivacyPolicyPage;
    }
    if (term == 'faq') {
      pageName = FaqPage;
    }
    if (term == 'CU') {
      pageName = ContactUsPage;
    }
    if (term == 'AU') {
      pageName = AboutUsPage;
    }



    const modal = await this.modalController.create({
      component: pageName,
      cssClass: 'my-custom-class',
    });
    await modal.present();
  }
}
