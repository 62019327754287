import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";
@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private deviceType: string;
  constructor(public platform: Platform) { }

  isMobileDevice(): boolean {
    if (this.platform.is('cordova')) {
      return true;
    }

    return false;
  }
  isIosMobileDevice = (): boolean => {
    if ((this.platform.is('ipad')) || (this.platform.is('iphone'))) {
      return true;
    }

    return false;
  }
  setDeviceType(devicetype: string) {
    this.deviceType = devicetype;
  }
  getDeviceType() {
    return this.deviceType;
  }
}
