import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DeviceService } from "../app/services/device/device.service";
import { Router } from "@angular/router";
import { Network } from "@ionic-native/network/ngx";
declare var window;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  rootPage: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private deviceServices: DeviceService,
    private navCtl: NavController,
    private network: Network

  ) {
    this.initializeApp();
  }
  ngOnInit() {
    if (this.platform.is('cordova')) {
      this.deviceServices.setDeviceType("mobile");
    } else {
      this.deviceServices.setDeviceType("web");
    }
    this.rootPage = 'tabs/home';
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.backgroundColorByName("black");

      this.navCtl.navigateRoot('tabs/home');
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      var ratio = window.devicePixelRatio || 1;
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
      };



      //  for iPhone X (375x812)
      if (iOS && screen.width == 1125 && screen.height == 2436) {
        document.querySelector("ion-app").className = document.querySelector("ion-app").className + " iphonex";
      }

      //  for iPhone 6 plus ,6s plus,7 plus, 8 plus   (414x736)
      if (iOS && screen.width == 1080 && screen.height == 1920) {
        document.querySelector("ion-app").className = document.querySelector("ion-app").className + " iphonex plus";
      }

      //  for iPhone 6,6s,7,8  (375x667)
      if (iOS && screen.width == 750 && screen.height == 1334) {
        document.querySelector("ion-app").className = document.querySelector("ion-app").className + " iphonex iphone-6-7-8";
      }

      //  for iPhone5, iphone5S  
      if (iOS && screen.width == 640 && screen.height == 1136) {
        document.querySelector("ion-app").className = document.querySelector("ion-app").className + " iphone5";
      }

      //  for ipad pro (1024x1366)
      if (iOS && screen.width == 2048 && screen.height == 2732) {
        document.querySelector("ion-app").className = document.querySelector("ion-app").className + " ipadpro";
      }

      //  for ipad iPad Mini4 , iPad Air 2, iPad Pro (9.7-inch)  (1024x768)
      if (iOS && screen.width == 1536 && screen.height == 2048) {
        document.querySelector("ion-app").className = document.querySelector("ion-app").className + " ipadmini";
      }


      // let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      //   console.log('network was disconnected :-(');
      // });

      // // stop disconnect watch
      // disconnectSubscription.unsubscribe();


      // // watch network for a connection
      // let connectSubscription = this.network.onConnect().subscribe(() => {
      //   console.log('network connected!');
      //   // We just got a connection but we need to wait briefly
      //   // before we determine the connection type. Might need to wait.
      //   // prior to doing any api requests as well.
      //   setTimeout(() => {
      //     if (this.network.type === 'wifi') {
      //       console.log('we got a wifi connection, woohoo!');
      //     }
      //   }, 3000);
      // });

      // // stop connect watch
      // connectSubscription.unsubscribe();
    });
  }





}
