// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  currentEnvironment: "dev",
  dev: {
    firebaseConfig: {
      apiKey: "AIzaSyB8xuonAvTOOZINdM9YhNHm7T1GoNvXWPU",
      authDomain: "oolsum-sit.firebaseapp.com",
      databaseURL: "https://oolsum-sit.firebaseio.com",
      projectId: "oolsum-sit",
      storageBucket: "oolsum-sit.appspot.com",
      messagingSenderId: "1037681613537",
      appId: "1:1037681613537:web:85f1b7fe3df9bd0c2b35a0"
      // apiKey: "AIzaSyCpeauL8Zn5hDrA5_bjL4rYoWrgjnNmFWA",
      // authDomain: "oolsum-developer.firebaseapp.com",
      // databaseURL: "https://oolsum-developer.firebaseio.com",
      // projectId: "oolsum-developer",
      // storageBucket: "oolsum-developer.appspot.com",
      // messagingSenderId: "1089186977432"
    }
  },
  prod: {
    firebaseConfig: {
      apiKey: "AIzaSyDD7TK47HPN2dm12oQ5DIIqUoaQMKjsKg4",
      authDomain: "oolsum-86f9a.firebaseapp.com",
      databaseURL: "https://oolsum-86f9a.firebaseio.com",
      projectId: "oolsum-86f9a",
      storageBucket: "oolsum-86f9a.appspot.com",
      messagingSenderId: "275891299319"
    }
  },
  sit: {
    firebaseConfig: {
      apiKey: "AIzaSyC9kNwYDA9hNzMQsqAaMQbuJ54hH-K9JLg",
      authDomain: "oolsum-7df3c.firebaseapp.com",
      databaseURL: "https://oolsum-7df3c.firebaseio.com",
      projectId: "oolsum-7df3c",
      storageBucket: "oolsum-7df3c.appspot.com",
      messagingSenderId: "988002411828"
    }
  },
  alpha: {
    firebaseConfig: {
      apiKey: "AIzaSyAk9d-sV-YH2MWk7etziIaJJGBVqEM1D8A",
      authDomain: "oolsum-alpha.firebaseapp.com",
      databaseURL: "https://oolsum-alpha.firebaseio.com",
      projectId: "oolsum-alpha",
      storageBucket: "oolsum-alpha.appspot.com",
      messagingSenderId: "956045509450"
    }
  }
};

