import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common'
@Pipe({
  name: 'date'
})
export class DatetransformPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }
  /**
    * Takes a string date and makes it 'yyyy-MM-dd HH:mm'.
    */
  transformDateAndTime(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'yyyy/MM/dd HH:mm');
    return value;
  }

  /**
   * Takes a string date and makes it 'yyyy-MM-dd HH:mm:ss.sss'.
   */
  transformFullDateTime(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'yyyy/MM/dd HH:mm:ss.sss');
    return value;
  }

  /**
   * Takes a string date and makes it 'yyyy-MM-dd'.
   */
  transformDateOnly(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'yyyy/MM/dd');
    return value;
  }

  transformTo12HTime(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'hh:mm a');
    return value;
  }
  transformDateTime(value: string) {
    var datetimePipe = new DatePipe('en-US');
    value = datetimePipe.transform(value, 'yyyy-mm-dd hh:mm');
    return value;
  }
  transform24HTo12H(value: string) {
    let currentDate = new Date();
    let toDate = this.transformDateOnly(currentDate.toISOString());
    value = toDate + ' ' + value;
    value = this.transformDateAndTime(value);
    value = this.transformTo12HTime(value);
    return value;
  }
  transformTo24HTime(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'HH:mm');
    return value;
  }

  minmaxDate(dates: any[]) {
    let plans: any = {
      "maxDate": this.max_date(dates),
      "minDate": this.min_date(dates),
    }
    var datediff = this.date_diff(plans.maxDate, plans.minDate);
    if (datediff == 0) {
      plans.plan = 0;
    }
    else {
      plans.plan = datediff + 1;
    }
    return plans;
  }

  max_date(all_dates) {
    var max_dt = all_dates[0],
      max_dtObj = new Date(all_dates[0]);
    all_dates.forEach(function (dt, index) {
      if (new Date(dt) > max_dtObj) {
        max_dt = dt;
        max_dtObj = new Date(dt);
      }
    });
    return max_dt;
  }

  min_date(all_dates) {
    let min_dt = all_dates[0],
      min_dtObj = new Date(all_dates[0]);
    all_dates.forEach(function (dt, index) {
      if (new Date(dt) < min_dtObj) {
        min_dt = dt;
        min_dtObj = new Date(dt);
      }
    });
    return min_dt;
  }

  date_diff(maxdate: any, mindate: any) {
    let diffInDays = Math.round(((Date.parse(maxdate)) - (Date.parse(mindate))) / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  dateDiffInHours(maxdate: any, mindate: any) {
    let diffInHours = Math.round(((Date.parse(maxdate)) - (Date.parse(mindate))) / (1000 * 60 * 60));
    return diffInHours;
  }

  dateDiffInMinutes(maxdate: any, mindate: any) {
    let diffInMinutes = Math.round(((Date.parse(maxdate)) - (Date.parse(mindate))) / (1000 * 60));
    return diffInMinutes;
  }

  dateDiffInSeconds(maxdate: any, mindate: any) {
    let diffInSeconds = Math.round(((Date.parse(maxdate)) - (Date.parse(mindate))) / (1000));
    return diffInSeconds;
  }

  replaceHyphenwithSlash(value: string) {
    value = value.replace(/-/g, "/");
    return value;
  }

  replaceSlashWithHyphen(value: string) {
    value = value.replace(/\//g, "-");
    return value;
  }

  getTimeInSeconds(date) {
    let currentDate = new Date();
    let timeInSeconds = this.dateDiffInSeconds(currentDate, date);
    return timeInSeconds;
  }
}

